import { createVNode, render } from "vue"
import CookieDialog from "~~/components/common/Cookies/Dialog.vue"
type UseCookieDialogOptions = {
  onClose?: () => void
}
export function useCookieDialog(options: UseCookieDialogOptions = {}) {
  const {
    onClose = () => {
      return
    }
  } = options
  const vm = createVNode(CookieDialog, {
    onClose
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
