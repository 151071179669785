<template>
  <CommonDialog ref="dialogRef" v-model="visible" :dialog-style="dialogStyle">
    <div class="cookies-dialog-container">
      <CommonCookiesOption
        v-for="(option, _i) in cookiesOptions"
        :key="_i"
        v-model="selectionsClone[option.key]"
        :label="option.label"
        :description="option.description"
        :require="option.require"
        :tip="option.tip"
        :disabled="option.disabled"
      />
      <button class="setting-btn" @click="() => handleSetting()">
        <CommonAnimationScrollText>{{ useTranslateI18n("common.cookies.settingBtnText") }}</CommonAnimationScrollText>
      </button>
      <button class="setting-btn accept-all" @click="() => handleSetting(true)">
        <CommonAnimationScrollText>{{ useTranslateI18n("common.cookies.drawer.acceptText") }}</CommonAnimationScrollText>
      </button>
    </div>
  </CommonDialog>
</template>
<script lang="ts" setup>
import { cookiesOptions } from "~~/constant/cookies"
import { useCookiesStore } from "~~/store/cookies"

interface DialogProps {
  onClose: () => void
}
const props = withDefaults(defineProps<DialogProps>(), {
  onClose: null
})
const visible = ref(true)
const dialogRef = ref()

const cookiesStore = useCookiesStore()
const { selections, setSelectedOption } = cookiesStore
const selectionsClone = reactive({ ...selections })

const dialogStyle = computed(() => {
  return {
    width: "auto",
    backgroundColor: "#ffffff",
    boxShadow: "0px 36px 70px rgba(0, 0, 0, 0.08), 0px 0px 25px rgba(0, 0, 0, 0.1)"
  }
})

const handleSetting = (isAll?: boolean) => {
  setSelectedOption((isAll ? "all" : selectionsClone) as any)
  dialogRef.value?.closeDialog()
  props.onClose && props.onClose()
}
</script>
<style lang="scss" scoped>
.cookies-dialog-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;

  .setting-btn {
    height: 42px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 60px;
    @include fontSemibold;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    &.accept-all {
      background: #ffe050;
      margin-top: -10px;
    }
  }
}

@include device-max-width-600 {
  .cookies-dialog-container {
    padding: 24px;
  }
}
</style>
