import { IPointOfficialWebsiteClickType } from "@/composables/utils/gtag/footerlink"
import { IClickType } from "@/constant/index"
import { IFooterLinkData, IFooterLinkDataObj } from "@/types/components/common/footerLink"
import {
  PORTRAIT_RETOUCHING,
  COLOR_AND_TONE,
  BACKGROUND_ADJUSTMENTS,
  EXCLUSIVE_PRESETS,
  BATCH_EDITS,
  START_PAGE_PATH,
  HELP_PAGE_PATH,
  PAYMENT_PAGE_PATH,
  COMPANY_PATH
} from "@/constant/route"

export enum LinkKey {
  AboutUs = "about-us",
  Blog = "blog",
  ContactUsDialog = "contact-dialog",
  ContactUs1 = "contact-1",
  CookieSetting = "cookie-setting",
  DownloadMac = "download-mac",
  DownloadWin = "download-win",
  ToFacebook = "to-facebook",
  ToInstagram = "to-Instagram",
  ToYoutube = "to-youtube",
  ToTiktok = "to-Tiktok",
  ToTwitter = "to-Twitter",
  ToPhone = "to-phone",
  PrivacySetting = "privacy-setting",
  TermsSetting = "terms-setting"
}

export const linkKeyPointParams = {
  [LinkKey.ToFacebook]: IPointOfficialWebsiteClickType.cF,
  [LinkKey.ToInstagram]: IPointOfficialWebsiteClickType.cI,
  [LinkKey.ToYoutube]: IPointOfficialWebsiteClickType.cY,
  [LinkKey.ToTiktok]: IPointOfficialWebsiteClickType.cTi,
  [LinkKey.ToTwitter]: IPointOfficialWebsiteClickType.cT
}

export const NavLinkSM = {
  [LinkKey.ToFacebook]: "https://www.facebook.com/evotoai/",
  [LinkKey.ToInstagram]: "https://www.instagram.com/evotoai/",
  [LinkKey.ToYoutube]: "https://www.youtube.com/@EvotoAI",
  [LinkKey.ToTiktok]: "https://www.tiktok.com/@evoto_ai",
  [LinkKey.ToTwitter]: "https://twitter.com/Evoto001"
}

export const NavLinkPointButtonNameDict = {
  [LinkKey.ToFacebook]: "bottomFacebookButton",
  [LinkKey.ToInstagram]: "bottomInstagram",
  [LinkKey.ToYoutube]: "bottomYoutube",
  [LinkKey.ToTiktok]: "bottomTiktok",
  [LinkKey.ToTwitter]: "bottomTwitter",
  [LinkKey.CookieSetting]: "bottomChangeCookie"
}

export const NavLinkSmImg = {
  [LinkKey.ToFacebook]: getCloudAssets(`/icons/common/i-fb.png`),
  [LinkKey.ToInstagram]: getCloudAssets(`/icons/common/i-i.png`),
  [LinkKey.ToYoutube]: getCloudAssets(`/icons/common/i-y.png`),
  [LinkKey.ToTiktok]: getCloudAssets(`/icons/common/i-tt.png`),
  [LinkKey.ToTwitter]: getCloudAssets(`/icons/common/i-t.png`)
}

export const linkArrDict: () => IFooterLinkData = () => {
  return [
    {
      children: [
        {
          type: IClickType.Click,
          key: LinkKey.AboutUs
        },
        {
          type: IClickType.Click,
          key: LinkKey.Blog
        }
      ]
    },
    {
      children: [
        {
          path: unref(PORTRAIT_RETOUCHING),
          type: IClickType.Skip
        },
        {
          path: unref(COLOR_AND_TONE),
          type: IClickType.Skip
        },
        {
          path: unref(BACKGROUND_ADJUSTMENTS),
          type: IClickType.Skip
        },
        {
          path: unref(EXCLUSIVE_PRESETS),
          type: IClickType.Skip
        },
        {
          path: unref(BATCH_EDITS),
          type: IClickType.Skip
        }
      ]
    },
    {
      children: [
        { type: IClickType.Skip, path: unref(START_PAGE_PATH) },
        { type: IClickType.Skip, path: unref(HELP_PAGE_PATH) }
      ]
    },
    {
      children: [
        { type: IClickType.Click, key: LinkKey.DownloadMac },
        { type: IClickType.Click, key: LinkKey.DownloadWin }
      ]
    },
    {
      children: [
        {
          type: IClickType.Click,
          key: LinkKey.ToFacebook,
          imgPath: NavLinkSmImg[LinkKey.ToFacebook]
        },
        {
          type: IClickType.Click,
          key: LinkKey.ToInstagram,
          imgPath: NavLinkSmImg[LinkKey.ToInstagram]
        },
        {
          type: IClickType.Click,
          key: LinkKey.ToYoutube,
          imgPath: NavLinkSmImg[LinkKey.ToYoutube]
        },
        {
          type: IClickType.Click,
          key: LinkKey.ToTiktok,
          imgPath: NavLinkSmImg[LinkKey.ToTiktok]
        },
        {
          type: IClickType.Click,
          key: LinkKey.ToTwitter,
          imgPath: NavLinkSmImg[LinkKey.ToTwitter]
        }
      ]
    }
  ]
}

export const linkBottomDict: () => IFooterLinkDataObj["children"] = () => [
  { type: IClickType.Click, key: LinkKey.TermsSetting },
  { type: IClickType.Click, key: LinkKey.PrivacySetting },
  { type: IClickType.Skip, path: getCloudAssets("/policy/cookies.html") },
  { type: IClickType.Click, key: LinkKey.CookieSetting }
]

export const newHomeLinkArrDict: () => IFooterLinkData = () => {
  return [
    {
      children: [
        {
          path: unref(PORTRAIT_RETOUCHING),
          type: IClickType.Skip
        },
        {
          path: unref(COLOR_AND_TONE),
          type: IClickType.Skip
        },
        {
          path: unref(BACKGROUND_ADJUSTMENTS),
          type: IClickType.Skip
        },
        {
          path: unref(EXCLUSIVE_PRESETS),
          type: IClickType.Skip
        },
        {
          path: unref(BATCH_EDITS),
          type: IClickType.Skip
        }
      ]
    },
    {
      children: [
        {
          path: unref(PAYMENT_PAGE_PATH),
          type: IClickType.Skip
        },
        {
          type: IClickType.Click,
          key: LinkKey.ContactUsDialog
        }
      ]
    },
    {
      children: [
        { type: IClickType.Click, key: LinkKey.DownloadMac },
        { type: IClickType.Click, key: LinkKey.DownloadWin }
      ]
    },

    {
      children: [
        {
          path: unref(COMPANY_PATH),
          type: IClickType.Skip
        },
        {
          key: LinkKey.TermsSetting,
          type: IClickType.Click
        },
        {
          key: LinkKey.PrivacySetting,
          type: IClickType.Click
        },
        {
          path: getCloudAssets("/policy/cookies.html"),
          type: IClickType.Skip
        },
        {
          key: LinkKey.CookieSetting,
          type: IClickType.Click
        }
      ]
    },
    {
      children: [
        { type: IClickType.Skip, path: unref(START_PAGE_PATH) },
        { type: IClickType.Skip, path: unref(HELP_PAGE_PATH) }
      ]
    }
  ]
}

export const nweHomeNavLinkSmImg = {
  [LinkKey.ToFacebook]: getCloudAssets(`/icons/common/new-home-footer-Facebook.png`),
  [LinkKey.ToInstagram]: getCloudAssets(`/icons/common/new-home-footer-Instagram.png`),
  [LinkKey.ToYoutube]: getCloudAssets(`/icons/common/new-home-footer-YouTube.png`),
  [LinkKey.ToTiktok]: getCloudAssets(`/icons/common/new-home-footer-Tiktok.png`),
  [LinkKey.ToTwitter]: getCloudAssets(`/icons/common/new-home-footer-Twitter.png`)
}

export const newHomeLinkBottomDict: () => IFooterLinkDataObj["children"] = () => [
  {
    type: IClickType.Click,
    key: LinkKey.ToFacebook,
    imgPath: nweHomeNavLinkSmImg[LinkKey.ToFacebook]
  },
  {
    type: IClickType.Click,
    key: LinkKey.ToInstagram,
    imgPath: nweHomeNavLinkSmImg[LinkKey.ToInstagram]
  },
  {
    type: IClickType.Click,
    key: LinkKey.ToYoutube,
    imgPath: nweHomeNavLinkSmImg[LinkKey.ToYoutube]
  },
  {
    type: IClickType.Click,
    key: LinkKey.ToTiktok,
    imgPath: nweHomeNavLinkSmImg[LinkKey.ToTiktok]
  },
  {
    type: IClickType.Click,
    key: LinkKey.ToTwitter,
    imgPath: nweHomeNavLinkSmImg[LinkKey.ToTwitter]
  }
]
