<template>
  <div
    class="switch-wrapper"
    :class="{ 'is-checked': checked, 'is-active': checked, 'is-disabled': disabled }"
    @click="handle"
  >
    <input ref="input" type="checkbox" role="switch" class="switch-wrapper__input" :checked="checked" />
    <span class="switch-wrapper__action"></span>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  modelValue: {
    type: Boolean
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(["update:modelValue", "change"])
const checked = ref(props.modelValue)
const input = ref()
watch(
  () => props.modelValue,
  (v) => {
    if (v === checked.value) {
      return
    }
    checked.value = v
  },
  {
    immediate: true
  }
)
watch(checked, (v) => {
  emits("update:modelValue", v)
})
const handle = () => {
  if (props.disabled) {
    return
  }
  checked.value = !checked.value
  emits("change", input.value?.checked)
}
</script>
<style lang="scss" scoped>
.switch-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  // width: 24px;
  // height: 13px;
  // line-height: 13px;
  width: 36px;
  height: 20px;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 48px;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s;

  .switch-wrapper__action {
    display: inline-block;
    // width: 10px;
    // height: 10px;
    // line-height: 13px;
    width: 16px;
    height: 16px;
    line-height: 19px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.85);
    transition: all 0.3s;
  }

  .switch-wrapper__input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
}
.switch-wrapper.is-checked .switch-wrapper__action {
  // margin-left: 12px;
  margin-left: 16px;
}

.is-active {
  background: #3083ff;
}

.is-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
