<template>
  <div class="cookies-selection">
    <h1 class="selection__header">
      <span class="selection__header-label">{{ useTranslateI18n(label) }}</span>
      <span class="selection__header-switch">
        <span v-if="require" class="tip">{{ useTranslateI18n(tip) }}</span>
        <CommonSwitch v-model="checked" :disabled="disabled" @change="handleCheck" />
      </span>
    </h1>
    <div class="selection__description">
      {{ useTranslateI18n(description) }}
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  modelValue: {
    type: Boolean
  },
  label: {
    type: String,
    default: ""
  },
  description: {
    type: String,
    default: ""
  },
  require: {
    type: Boolean,
    default: false
  },
  tip: {
    type: String,
    default: "Required"
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(["update:modelValue", "change"])
const checked = ref(props.modelValue)

watch(
  () => props.modelValue,
  (v) => {
    if (v === checked.value) {
      return
    }
    checked.value = v
  },
  {
    immediate: true
  }
)
watch(checked, (v) => {
  emits("update:modelValue", v)
})

function handleCheck(v: boolean) {
  emits("change", v)
}
</script>

<style lang="scss" scoped>
.cookies-selection {
  // margin-top: 16px;

  .selection__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 17px;
    // line-height: 17px;

    &-label {
      @include fontSemibold;
      font-size: 20px;
      line-height: 24px;
    }

    &-switch {
      display: inline-flex;
      align-items: center;
      .tip {
        display: inline-block;
        color: rgba(0, 0, 0, 0.55);
        margin-right: 8px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    @include device-max-width-600 {
      &-label {
        font-size: 18px;
        line-height: 21px;
      }

      &-switch {
        .tip {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  .selection__description {
    width: 358px;
    margin-top: 16px;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.55);
  }

  @include device-max-width-600 {
    .selection__description {
      width: 232px;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
</style>
