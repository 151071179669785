<template>
  <div class="e-switch-language">
    <client-only>
      <el-dropdown ref="lDropdown" trigger="click" popper-class="e-switch_dropdown" @visible-change="dropdownClick">
        <div class="e-switch-language-c">
          <LanguageLogo class="lg" />
          <span class="c-text">{{ language_text }}</span>
          <el-icon :class="[arrowIconActive ? 'arrow-icon-active' : '']"> <arrow-down></arrow-down> </el-icon>
        </div>
        <template #dropdown>
          <div class="language-list-wrapper">
            <div v-for="lItem in Global_Language" :key="lItem.value" class="l-item" @click="languageClick(lItem)">
              <img
                :class="[language_text === lItem.name ? 'icon-active' : '', 'icon']"
                src="@/assets/icons/common/success-icon.png"
                alt=""
              />
              <span class="l-text">{{ lItem.name }}</span>
            </div>
          </div>
        </template>
      </el-dropdown>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { Global_Language, setGlobalLanguage } from "@/constant/i18n-country"
import { ArrowDown } from "@element-plus/icons-vue"
import type { DropdownInstance } from "element-plus"
import LanguageLogo from "@/assets/icons/common/language-logo.svg"

const lDropdown = ref<DropdownInstance>()
let arrowIconActive = ref(false)

function handleDropVisible(visible: boolean) {
  if (!lDropdown.value) return
  if (visible) {
    lDropdown.value.handleClose()
  } else {
    lDropdown.value.handleOpen()
  }
}

function languageClick(lItem: any) {
  handleDropVisible(true)
  document.documentElement.scrollTop = 0
  setGlobalLanguage(lItem.value)
}

const dropdownClick = (visible) => {
  arrowIconActive.value = visible
}

let language_text = computed(() => {
  return Global_Language.find((l) => l.value === useI18nCountry().value)?.name || Global_Language[0].name
})
</script>

<style scoped lang="scss">
.e-switch-language {
  :deep(.el-dropdown) {
    outline: none;
  }
  &-c {
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    outline: none;
    :deep(.el-icon) {
      svg {
        transition: transform linear 0.2s;
      }
      &.arrow-icon-active {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .lg {
      width: 16px;
      height: 16px;
    }
    .c-text {
      margin: 0 6px;
    }
  }
}
</style>
<style lang="scss">
.el-dropdown__popper.e-switch_dropdown {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.33), 0px 36px 70px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(15px);
  .el-scrollbar {
    border-radius: 10px;
  }
  .el-popper__arrow {
    display: none;
  }
  .language-list-wrapper {
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .l-item {
      display: flex;
      align-items: center;
      padding: 4px 12px;
      border-radius: 3px;
      .icon {
        width: 16px;
        margin-right: 4px;
        opacity: 0;
        &.icon-active {
          opacity: 1;
        }
      }
      &:hover {
        background-color: #ffe050;
      }
      .l-text {
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
      }
    }
  }
}
</style>
